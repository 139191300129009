var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import gql from "graphql-tag";
import SmallBlogPostCard from "./SmallBlogPostCard.vue";
import ReviewsSection from "../../ui/ReviewsSection.vue";
import { mapState } from "vuex";
import map from "lodash/map";
export default Vue.extend({
    components: {
        SmallBlogPostCard: SmallBlogPostCard,
        ReviewsSection: ReviewsSection,
    },
    apollo: {
        liveBlogPosts: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        liveBlogPosts(first: 4) {\n          edges {\n            node {\n              id\n              ...SmallBlogPostCard_post\n            }\n          }\n        }\n      }\n      ", "\n    "], ["\n      {\n        liveBlogPosts(first: 4) {\n          edges {\n            node {\n              id\n              ...SmallBlogPostCard_post\n            }\n          }\n        }\n      }\n      ", "\n    "])), SmallBlogPostCard.options.fragments.post),
        contentPage: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        contentPage(key: \"home\") {\n          originalBannerImageUrl\n        }\n      }\n    "], ["\n      {\n        contentPage(key: \"home\") {\n          originalBannerImageUrl\n        }\n      }\n    "]))),
    },
    data: function () { return ({
        multiLingualTestimonials: {
            en: [
                {
                    person: "Sarah Lee",
                    content: "The StudentGuru team were super helpful and friendly in helping me prepare for my upcoming student life in Sydney. They were really patient with my queries, and gave me great suggestions on places to stay and even made my accommodation booking for me.",
                },
                {
                    person: "Jason",
                    content: "What an easy to use site. I managed to buy a prepaid SIM card easily and even got it delivered to me before I left my country. 100% recommend.",
                },
                {
                    person: "Anita",
                    content: "I was really stressed about getting accommodation before arriving in Australia, but the team at StudentGuru were so knowledgeable about local areas and found an incredible apartment for me that suits my budget and is close to my uni.",
                },
            ],
            "zh-hans": [
                {
                    person: "Cindy Wang",
                    content: "StudentGuru 团队回复很及时，服务态度专业，就是极少数情况下对流程了解不够透彻需要我自己摸索，总体很不错。",
                },
                {
                    person: "Siyue",
                    content: "通过Studentguru 在国内购买了电话卡， 下飞机后直接在悉尼机场取卡， 过程非常简单方便， 还有特别制定的机场取卡流程图， 很暖心",
                },
                {
                    person: "Rachel Feng",
                    content: "本来只是朋友介绍来网站上看一下， 发现住宿有很多的选择， 于是便试着咨询了一下， 客服很专业， 回复也很快。 在问了我的一些基本要求后给我发了3到4个住宿选择包括看房视频什么的。 并且还提供了机场接送服务。 感觉虽然网站看起来简单但是后续服务非常棒。 会推荐",
                },
            ],
        },
    }); },
    computed: __assign({ testimonials: function () {
            return this.multiLingualTestimonials[this.locale];
        }, posts: function () {
            if (!this.liveBlogPosts) {
                return [];
            }
            return map(this.liveBlogPosts.edges, "node");
        } }, mapState(["locale"])),
});
var templateObject_1, templateObject_2;
